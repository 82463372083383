
.row{
  margin:0 !important;


}
.who_are_we_box{
  margin-top: 5px!important;
}
input[type="range"]::-webkit-slider-thumb {
    background: #EE7A37 !important;
  }
  input[type="range"]::-moz-range-thumb {
    background: #EE7A37 !important;
  }
  input[type="range"]::-ms-thumb {
    background: #EE7A37 !important;
  }
  input[type="range"]::-webkit-slider-track {
    background: #EE7A37 !important;
  }
  input[type="range"]::-moz-range-track {
    background: #EE7A37 !important;
  }
  input[type="range"]::-ms-track {
    background: #EE7A37 !important;
  }
  
   /* .inp{
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000040;
    border: 1px solid #00000040;
    background-color: #F3F9F9;
   } */
   #secinp{
    resize: none;
    line-height: 100px;
    min-width: 100%;
    padding: 3%;
    padding-top: -50px;
   }
 .bg-salmon::placeholder{
  color:#EE7A37;
 }
 .bg-salmon{
  border: 1px solid #EE7A37!important;
 }

 .bg-salmon2::placeholder{
  color:#EE7A37;
 }
 .bg-salmon2{
  border: 1px solid #EE7A37!important;
 }

 @media screen and (max-width: 1000px) {
  .mapdiv{
    display: flex;
    flex-direction: column;
    
  }
}
@media screen and (min-width: 990px) {
  .landingpara{
    max-height:300px;
    min-height:300px;
    
  }
}
.gradiantbar{
  background-image: linear-gradient(to right ,#EE7A3787, #F5F5F5);
}
.cardtop{
 
    background-image: linear-gradient(to right,#FE5858, #EE9617);
  
}
.anibtn{
  height: 50px;
  width:250px;
 box-shadow:  0px 9px 15px #00000029;
}
.anibtn:hover{
  box-shadow:  0px 9px 15px #EE7A37;
  transition: all .5s ease-in-out;
}
.aniarrow{
 position: absolute;
 right: 50px;
 font-size: 15px;
}
.anibtn:hover .aniarrow {
 right:35px;
 font-size: 25px;
 bottom: 7px;
 
 transition: right  .5s ease-in-out;

 }

 .anibtnleft{
  height: 50px;
  width:250px;
 box-shadow:  0px 9px 15px #00000029;
}
.anibtnleft:hover{
  box-shadow:  0px 9px 15px #EE7A37;
  transition: all .5s ease-in-out;
}
.aniarrowleft{
 position: absolute;
 left: 50px;
 font-size: 15px;
}
.anibtnleft:hover .aniarrowleft {
 left:35px;
 font-size: 25px;
 bottom: 7px;
 
 transition: left  .5s ease-in-out;

 }
 @media screen and (min-width: 990px) {
  .box{
   position: absolute;
    top: -10%;
  
  }
}

.apply-btn{
  color: #EE7A37;
  background-color: #F7ECE8;
}
.apply-btn:active{
  color: #F7ECE8;
  background-color: #EE7A37;
}
.archieves:hover{
  border: 1px solid #11884F;
  background: #fff;
  border-radius: 36px;
  box-shadow: 0px 51.85px 46.3px #00000012;
  transform: scale(1.1);

  transition: transform .5s ease-in-out;

}

.archieves_main{
  margin-bottom: 20px!important;
}
.videos:hover{
 transform: scale(1.1);
}

@media screen and (max-width: 1000px) {
  .res_video{
  
    width:90%!important;
  }
}
 .apply-btn:hover{
  color: #EE7A37;
 }


