.nav-link:hover {
   color:#008c53 !important;
  
   transform: scale(1.2);
   transition: all .3s ease-in-out;
   /* font: 600!important; */
  
  }

.nav-link{
    color: #1B252C !important;
    font-size: 16px;
   /* font: 300 !important; */
   width: 100%;
   line-height: 20px;
}
.active{
  color: #008c53  !important;
  font-size: 16px;
 text-decoration: none;
 display: flex;
 justify-content: center;
 align-items: center;
}

/* .nav-item{
  padding: 5px;
}
.nav-item:hover{
    padding: 0;
    transition: all .3s ease-in-out;
} */

.arrow{
  color: #7A7878;
  margin-left: 5%;
  cursor: default;
}
.arrpara1:hover .arrow{
  color:  #EE7A37;
  margin-left: 7%!important;
  transition: all .3s ease-in-out;
}
.arrow2{
  color: #7A7878;
  margin-left: 5%;
  cursor: default;
}
.arrpara2:hover .arrow2{
  color:  #EE7A37;
  margin-left: 7%!important;
  transition: all .3s ease-in-out;
}
/* .btns{
 box-shadow: 3px 3px 6px gray;
}
.btns:hover{
  box-shadow: 3px 3px 6px #EE7A37;
  transition: all .5s ease-in-out;
 } */

 /* @media screen and (min-width: 900px) {
  .footer{
    border-left: 3px dashed white
  }
} */
/* input::file-selector-button {
  border:1px solid #EE7A37;
  background-color: #FFC6A5;
  color:white;
} */

input::file-selector-button{
  border:1px solid #EE7A37;
  background-color: #FFC6A5;
  color:white;
  padding:8px 15px;
  border-radius: 10px;
  margin-right: 10px;
}
.social_media a{
   text-decoration: none;
}